.selectWrapper {
  position: relative;
  width: 100%;
}

.button {
  display: flex;
  gap: 15px;
  flex-direction: row;
  justify-content: space-between;
  color: var(--primary-color) !important;
  padding: 5px 0 5px 28px;
  background: none;
  border: none;
  width: 100%;

  &:hover {
    cursor: pointer;
    color: inherit;
    background-color: #deebff;
  }
}

.more {
  justify-content: space-between;
}

.add {
  justify-content: flex-start;
}

.addLabel {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  padding: 2px 3px 0;

  &:hover {
    cursor: pointer;
    color: inherit;
    background-color: #deebff;
  }
}

.customOption {
  height: 100% !important;
  transition: background 60ms !important;
  cursor: pointer !important;

  &:hover {
    transition-delay: 60ms !important;
    background: #deebff !important;
    color: var(--primary-color);
  }
}

.disabled {
  color: var(--red) !important;
  height: 35px;
  cursor: not-allowed !important;
  opacity: 0.5;

  &::before {
    content: '';
    display: block;
    background: url('src/assets/images/not-allowed.svg') no-repeat;
    width: 20px;
    height: 20px;
    float: left;
    margin: 0 6px 0 0;
  }
}

.multiValue {
  font-size: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.multiValueCount {
  color: #ca481f;
  font-weight: bold;
  margin-left: 15px;
}

.multiValueFilter {
  margin-left: 8px;
}

.schoolsInfo {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ff96af;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: 0;
}

.errorMessage {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ff96af;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  bottom: -25px;
}

.comment {
  color: var(--black-20);
  opacity: 0.5;
  font-size: 12px;
  font-weight: 400;
}

.commentSelected {
  color: var(--white) !important;
}
