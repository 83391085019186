.loading {
  position: relative;
  height: calc(50vh - 60px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingInline {
  position: relative;
  display: inline;
  justify-content: center;
  align-items: center;
}
