.basicOverlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10 !important;
}

.basicClassModal {
  position: relative;
  background: var(--background-modal);
  border-radius: 30px;
  max-width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px;
  align-content: center;
  max-height: 90vh;
  overflow: auto;

  &::-webkit-scrollbar {
    margin: 5% 0;
  }

  &::-webkit-scrollbar-button {
    margin: 5% 0;
  }
}
