$primary-color: #324797; //new theme
$secondary-color: #CA481F; //new theme
$brand-bg: #F1F7FF; // new theme
$main-text-dark: #0E142C; //new theme
$dashboard-widget: #17142E; // new theme
$main-shadow: rgba(114, 152, 241, 0.15); //new theme
$main-box-shadow: 0px -1px 20px rgba(114, 152, 241, 0.45); //new theme
$translucent-text: #17142E; // new theme
$translucent-opacity: rgba(23, 20, 46, 0.5); // new theme
$main-shadow: rgba(114, 152, 241, 0.15); //new theme
$secondary-hover: #ebefff; // new theme
$background-light-primary: #E0E6FF; //new theme
$border-light-primary: #cfd4f1; //new theme
$background-shade: #f8f8f8;
$background-modal: #F1F7FF; // new theme
$success: #4caf50;
$warning: orange;
$error: red; // new theme
$color-text: #56647d;
$color-text-weak: #868686;
$color-text-strong: #000;
$color-text-heading: #56647d;
$color-text-link: red;
$color-text-link-hover: green;
$white: #fff;
$black: #000;
$color-border: #ebecf0;
$color-background: #f157be;
$primary-link: #0352CC;
$color-default-icon-color: #42526e;
$color-active-icon-color: #3240A1;
$primary-button: #304594;
$primary-button-hover: #3A4C97;
// font-weights
$light: 300;
$regular: 400;
$medium: 500;
$semi-bold: 600;
$bold: 700;
$extra-bold: 900;
// Font Variables
$primary-font: "Teko", // new theme
Sans-Serif;
$secondary-font: "Hind", sans-serif;
$menu-color: #deebff;
$menu-hover-bg: #283c52;
$dark-color: #42526e;
$light-color: #56647d;
$brand-color: #304594, 100%;
$second-brand-color: #344159;
$second-brand-bg: #f4f8fc;
$brand-active: #3240A1;
$border: 1px solid #FFFFFF;
@keyframes slideInLeft {
    0% {
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }
    to {
        transform: translateZ(0);
    }
}
