.modal {
  width: 564px;
  height: 556px;
  background: var(--white);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 0;
  padding: 0;

  .closeIcon {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 1;

    &:hover {
      transform: rotate(90deg);
      transition: all 0.15s ease-in-out 0s;
    }
  }

  &::before {
    content: '';
    background: url('/src/assets/images/modal/wave.svg') no-repeat;
    position: absolute;
    background-size: 100% 100%;
    width: 100%;
    height: 335px;
  }

  .container {
    padding: 7% 9%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    .header {
      position: relative;

      .logo {
        width: 103px;
        height: 53px;
      }

      .title {
        margin: 15px 0 0;
        font-weight: 700;
        font-size: 44px;
        line-height: 70px;
        color: var(--white);
      }

      .text {
        font-weight: 400;
        font-size: 18px;
        line-height: 145.1%;
        color: var(--white);
        white-space: pre-line;
      }
    }

    .contacts {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 25px;

      .contactBox {
        display: flex;
        align-items: center;

        .contactContent {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 10px;

          .contactText {
            font-weight: 600;
            font-size: 18px;
            line-height: 29px;
          }

          .contactConnect {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
          }
        }
      }
    }

    .backBtn {
      margin-top: 50px;
      max-width: 432px;
      width: 100%;
      height: 52px;
      border-radius: 30px;
      color: #353a6a;
      border: 1px solid #353a6a;
      background-color: var(--white);
    }
  }
}
