.authContainer {
  background: linear-gradient(117.57deg, #232c52 10.68%, #181f3b 92.74%);
  height: 100vh;

  &::before {
    content: '';
    background: linear-gradient(111.38deg, #3b4280 -76.22%, #3458b6 106.5%);
    opacity: 0.3;
    border-radius: 10px;
    margin: 3% 15%;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    transform: rotate(-13deg);
  }
}

.authMainWrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 3% 15%;
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(25px);
  border-radius: 10px;
  display: flex;

  .leftWrapper {
    min-width: 57%;
    color: var(--black);
    background-color: var(--white);
    border-radius: 10px 0 0 10px;
    overflow: hidden;
    position: relative;

    .basketball {
      height: 100%;
      min-height: 784px;
      min-width: 572px;
      position: absolute;
      bottom: -170px;
      left: 50%;
      transform: translate(-50%, 0);
    }
  }

  .rightWrapper {
    display: flex;
    padding: 60px;

    .rightContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      .backBtn {
        position: absolute;
        padding: 0;
        right: 36%;
        top: 5%;
      }

      .logoIcon {
        max-width: 266px;
        max-height: 137px;
        width: 100%;
      }

      .title {
        display: block;
        text-align: center;
        color: var(--black);
        font-weight: 700;
        font-size: 50px;
        line-height: 80px;
      }

      .accessText {
        margin-top: 5px;
        display: block;
        text-align: center;
        color: var(--black);
        font-weight: 400;
        font-size: 18px;
        line-height: 29px;
      }

      .actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 40px 20px;

        .editBtn {
          width: 100%;
          max-width: 216px;
          height: 52px;
          opacity: 0.9;
          color: var(--white);
          background: linear-gradient(111.08deg, #353a6a 22.16%, #2e4c97 106.61%);
          font-weight: 700;
          font-size: 20px;
          line-height: 32px;
        }

        .contactBtn {
          margin-top: 10px;
          font-weight: 500;
          font-size: 20px;
          width: 100%;
          max-width: 216px;
          height: 52px;
          color: #353a6a;
          border-radius: 30px;
          border: 1px solid #353a6a;
          background-color: transparent;
        }
      }

      .footerContent {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;

        .accountText {
          color: var(--black);
          font-weight: 400;
          font-size: 16px;
          margin-right: 7px;
          text-align: center;
        }

        .signInBtn {
          padding: 0;
          font-weight: 600;
          font-size: 16px;
          line-height: 26px;
          background: linear-gradient(111.08deg, #353a6a 22.16%, #2e4c97 106.61%);
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          text-shadow: 0 4px 17px rgba(44, 116, 255, 0.25);
        }
      }
    }
  }
}
