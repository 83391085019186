@import "./src/theme/_config.scss";

.error-404 {
  &.container {
    margin-top: 10%;
  }

  &.left-panel {
    h1 {
      color: $secondary-color;
      font-family: Teko, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 96px;
      text-transform: uppercase;
    }

    p {
      font-family: Hind, sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 29px;
    }
  }
}

.error-404-illustration {
  z-index: -100;
  position: absolute;
  height: 95%;
  right: 0;
  bottom: 0;
}

.network-error {
  margin-top: 15px;
  text-align: center;
  .img {
    margin: 60px 0;
  }
  .title {
    font-weight: 700;
    font-size: 30px;
    line-height: 48px;
    color: #0d132e;
  }
  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #0d132e;
    margin: 20px 0;
  }
  .retry-btn {
    width: 200px;
    height: 50px;
    border: 1px solid #ca481f;
    color: #ca481f;
    border-radius: 40px;
    background: none;
    &:hover {
      box-shadow: 0 0 10px rgba(202, 72, 31, 0.6);
 }  }
}
