.wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  min-height: max(730px, calc(100vh - 134px));
}

.wrapper:not(.expandable) {
  @media (--desktop) {
    grid-template-columns: 1fr 6fr;
  }
}

.mainLoader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
