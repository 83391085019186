.modal {
  position: absolute;
  top: 33%;
  left: calc(50% - 160px);
  margin: auto;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10 !important;
}
.wrapper {
  min-width: 310px;
  max-width: 50%;
  min-height: 150px;
  max-height: 50%;
  box-shadow: 0 -1px 12px rgba(114, 152, 241, 0.45);
  border-radius: 30px;
  background: #FFFFFF;
  padding: 25px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.stop {
  width: 50px;
  height: 50px;
}

.close {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background: none;
}
