.modal {
  width: 564px;
  height: 516px;
  background: var(--white);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 0;
  padding: 0;

  .closeIcon {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 1;

    &:hover {
      transform: rotate(90deg);
      transition: all 0.15s ease-in-out 0s;
    }
  }

  &::before {
    content: '';
    background: url('/src/assets/images/modal/wave.svg') no-repeat;
    position: absolute;
    top: -10px;
    background-size: 100% 100%;
    width: 100%;
    height: 220px;
  }

  .container {
    padding: 7% 9%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    .typeIcon {
      width: 100%;
      height: 100%;
      max-width: 190px;
      max-height: 190px;
      position: relative;
      margin-left: 46px;
    }

    .header {
      position: relative;

      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 117%;
        color: var(--black);
        margin: 0;
        white-space: pre-line;
      }

      .text {
        margin: 25px 0 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 126.1%;
        color: var(--black);
        white-space: pre-line;
      }
    }

    .actions {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: center;

      .approve {
        width: 216px;
        height: 52px;
        opacity: 0.9;
        color: var(--white);
        background: linear-gradient(111.08deg, #353a6a 22.16%, #2e4c97 106.61%);
        border-radius: 30px;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      .cancel {
        width: 216px;
        height: 52px;
        border-radius: 30px;
        color: #353a6a;
        border: 1px solid #353a6a;
        background-color: var(--white);
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      .approveRed {
        background: #ff2f48;

        &:hover {
          box-shadow: var(--red-box-shadow);
        }
      }

      .cancelRed {
        border: 1px solid #ff2f48;
        color: #ff2f48;

        &:hover {
          box-shadow: var(--red-box-shadow);
        }
      }
    }
  }
}
