//TODO move to GRID component
@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-material.css";

@import "src/theme/_config";
@import "theme/_inputs";

@import "sass/_ag-grid-custom.scss";

@import url("https://fonts.googleapis.com/css2?family=Teko&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Hind:wght@400;500;600&display=swap");

* {
  outline: none;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--white);
  color: var(--black-20);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  font-family: var(--secondary-font), serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  overflow: auto;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

//   scroll bar
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #334785;
  //outline: #333 solid 1px;
  border-radius: 5px;
}

::-webkit-scrollbar-corner {
  display: none;
}

::-webkit-scrollbar-track:hover {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.content-container {
  display: flex;
  flex-direction: column;
  margin: 0 65px 0 45px;
  gap: 10px;
}

.content-main-container {
  margin: 20px 0;
  padding: 0;
}

.ReactVirtualized__Grid__innerScrollContainer {
  & > div > div[class^=' css-'],
  & > div > div[class^='css-'] {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    padding: 5px 10px 5px 28px;
    background-color: white;
    color: #0E142C;

    &:hover, &:focus, &:active {
      cursor: pointer;
      color: inherit;
      background-color: #deebff;
    }
  }
}

/*AUTOFILL RESETS*/
input:-webkit-autofill,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
select:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px #fafbfc inset !important;
  box-shadow: 0 0 0 30px #fafbfc inset !important;
  -webkit-transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
  transition: background-color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}

button {
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
