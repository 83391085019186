.menu-item {
  display: flex;
  padding-top: 10px;
  margin-bottom: 4px;
  vertical-align: middle;
  padding-left: 65px;
  height: 38px;
  color: var(--black-20);
  text-decoration: none !important;
  position: relative;

  &.active {
    color: #fff;
    background: none;
    border-radius: 0 30px 30px 0;
    opacity: 0.9;
    background: var(--gradient-east-bay);

    img {
      filter: invert(100%) sepia(100%) saturate(9%) hue-rotate(155deg) brightness(102%) contrast(105%);
    }
  }

  img {
    margin-right: 16px;
  }
}

.menu-new-item-flag {
  color: var(--secondary-color);
  position: absolute;
  font-size: 10px;
  font-weight: bolder;
  padding: 0 5px;
  animation: glow 1s ease-in-out infinite alternate;
  text-shadow: 0 0 5px var(--brand-bg), 0 0 7px var(--brand-bg), 0 0 9px var(--brand-bg), 0 0 11px var(--brand-bg), 0 0 12px var(--brand-bg);
}


