.PhoneInputCountrySelect {
  color: blue;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  background-color: DodgerBlue;
  border-radius: 10px;
}

.PhoneInputCountrySelect option {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  transition: all 300ms;
}

.PhoneInputCountrySelect > options {
  border-radius: 15px;
}
