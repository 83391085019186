.modal {
  width: 564px;
  height: 556px;
  background: var(--white);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 0;
  padding: 0;

  .closeIcon {
    position: absolute;
    top: 25px;
    right: 25px;
    z-index: 1;

    &:hover {
      transform: rotate(90deg);
      transition: all 0.15s ease-in-out 0s;
    }
  }

  &::before {
    content: '';
    background: url('/src/assets/images/modal/wave.svg') no-repeat;
    position: absolute;
    top: -10px;
    background-size: 100% 100%;
    width: 100%;
    height: 220px;
  }

  .container {
    padding: 7% 9%;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    .typeIcon {
      width: 100%;
      height: 100%;
      max-width: 190px;
      max-height: 190px;
      position: relative;
      margin-left: 46px;
    }

    .header {
      .title {
        font-weight: 700;
        font-size: 24px;
        line-height: 117%;
        color: var(--black);
        margin: 0;
      }

      .text {
        margin: 18px 0 0;
        font-weight: 400;
        font-size: 18px;
        line-height: 126.1%;
        color: var(--black);
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      gap: 10px;
      width: 100%;

      .resendBtn {
        max-width: 432px;
        width: 100%;
        height: 52px;
        border-radius: 30px;
        color: var(--white);
        font-weight: 700;
        font-size: 20px;
        line-height: 32px;
        background-color: #ff2f48;

        &:hover {
          box-shadow: var(--red-box-shadow);
        }
      }

      .contactBtn {
        max-width: 432px;
        width: 100%;
        height: 52px;
        font-weight: 500;
        font-size: 18px;
        line-height: 29px;
        color: #ff2f48;
        border: 1px solid #ff2f48;
        background-color: transparent;

        &:hover {
          box-shadow: var(--red-box-shadow);
        }
      }
    }
  }
}
