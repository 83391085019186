@import "_mixins";
@import "_config";

.form__input {
  position: relative !important;
  width: 100%;
  flex-direction: row !important;
  margin-bottom: 19px;
  background: #fff;
  border-radius: 40px;
  box-shadow: var(--non-focus-shadow);
  transition: background .1s ease-out, box-shadow .15s cubic-bezier(.47, .03, .49, 1.38);
  height: 50px;
  padding: 0 22px;
  font-size: 16px;

  &.disabled {
    opacity: .5;
    cursor: not-allowed;

    &:hover {
      box-shadow: var(--non-focus-shadow);
    }
  }

  &:hover {
    box-shadow: 0px -1px 13px rgba(114, 152, 241, 0.5);
  }


  &_label {
    padding: 0 20px;
    font-size: 12px;
    font-weight: 600;
    color: var(--mid-grey);
    line-height: 19.2px;
  }

  input {
    margin: auto;
    height: fit-content;
    background: none !important;
    display: inline-flex;
    box-sizing: border-box;
    border: none;
    font-style: normal;
    font-size: 16px;
    outline: none;
    flex-grow: 1;
    width: 100%;
  }


  &.muted-input {
    &:focus {
      box-shadow: none;
      border: none;
    }
  }

  &.invalid {
    border: 1px solid red;
  }
}

.form__input:hover:has(input[readonly]) {
  box-shadow: var(--non-focus-shadow);
}

.text-area-input {
  border-radius: 16px;
  height: 100px;
}

.deviceNotes {
  height: 140px;
}
