.logo-button {
  background: none;
  outline: none;
  border: none;
  border-radius: 15px;
}

.current-selector {
  margin-bottom: 15px;
}

.menu-bar {
  font-weight: 500;
  width: 260px;
  position: absolute;
  background: var(--white);
  z-index: 3;
  padding: 0 35px 20px 0;
  border-radius: 30px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.11);

  &:not(.expandable) {
    @media (width >=1281px) {
      position: inherit;
      background: none;
      box-shadow: none;
      border: none;
      display: flex;
      flex-direction: column;
    }
  }

  .menu-dropdown {
    margin-left: 65px;
    margin-bottom: 0;
  }
}

.menu-bar-open-button {
  position: absolute;
  top: 22%;
  right: -30px;
  width: 60px;
  height: 60px;
  border: none;
  background: var(--white);
  display: flex;
  align-items: center;
  border-radius: 50%;
  font-weight: 600;
  font-size: 12px;
  line-height: 100%;
  text-align: center;
  color: var(--primary-color);

  &:not(.expandable) {
    @media (width >=1281px) {
      display: none;
    }
  }
}

.open-menu {
  left: -30px;
  animation-name: open;
  animation-duration: 0.3s;
  visibility: visible;
}

.close-menu {
  left: -230px;
}

@keyframes open {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}