/* noinspection Stylelint */
.tippy-box[data-theme~='wave'] {
  background-repeat: no-repeat, no-repeat;
  background-position: right bottom, center center;
  background-size: 25%, 100%;
  border-radius: 10px;
  color: #fff;

  /* noinspection Stylelint */

  .tippy-content {
    padding: 15px 20px;
  }
}

/* noinspection Stylelint */
.tippy-box[data-theme~='wave'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: var(--primary-color);
}

/* noinspection Stylelint */
.tippy-box[data-theme~='wave'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: var(--primary-color);
}

/* noinspection Stylelint */
.tippy-box[data-theme~='wave'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: var(--primary-color);
}

/* noinspection Stylelint */
.tippy-box[data-theme~='wave'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: var(--primary-color);
}
