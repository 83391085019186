.container {
  padding-top: 4px;
  border-top: 1px solid rgba(51, 71, 133, 0.5);
}

.button {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  color: var(--primary-color) !important;
  padding: 5px 0 5px 12px;
  background: none;
  border: none;

  &:hover {
    cursor: pointer;
    color: inherit;
    background-color: #deebff;
  }
}

.addLabel {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  padding: 2px 3px 0;

  &:hover {
    cursor: pointer;
    color: inherit;
    background-color: #deebff;
  }
}
