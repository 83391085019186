:root {
  --white: #fff;
  --black: #000;
  --black-20: #202020;
  --primary-color: #324797;
  --pink-salmon: #ff96af;
  --secondary-color: #ca481f;
  --brand-bg: #f1f7ff;
  --brand-gradient: linear-gradient(121deg, #353a6a 0%, #2e4c97 100%);
  --main-text-dark: #0e142c;
  --main-text-light: rgba(23, 20, 46, 0.5);
  --dashboard-widget: #17142e;
  --main-shadow: rgba(114, 152, 241, 0.15);
  --non-focus-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.12);
  --main-box-shadow: 0 -1px 20px rgba(114, 152, 241, 0.45);
  --red-box-shadow: 0 -1px 20px rgba(255, 47, 72, 0.45);
  --translucent-text: #17142e;
  --translucent-opacity: rgba(23, 20, 46, 0.5);
  --secondary-hover: #ebefff;
  --background-light-primary: #e0e6ff;
  --border-light-primary: #cfd4f1;
  --background-modal: #fff;
  --primary-font: 'Teko', sans-serif;
  --secondary-font: 'Hind', sans-serif;
  --blue: #1847a6;
  --green: #13bd55;
  --green-status: #54bf64;
  --gray-status: rgba(23, 20, 46, 0.2);
  --mid-grey: #58586d;
  --green-gradient: linear-gradient(139deg, #367935 0%, #43ae41 100%);
  --gradient-east-bay: linear-gradient(121deg, #353a6a 0%, #2e4c97 100%);
  --gradient-deep-blue: linear-gradient(111deg, #353a6a 22.16%, #2e4c97 106.61%);
  --Brick-Red: #b0294a;
  --Coral-Red: #ff2f48;
  --Gradient-East-Bay: #353a6a;
  --gray: #d3d3d3;
  --red: #c90c1f;
  --yellow: #ffc633;
  --success-green: #4cea71;
  --danger-orange: #ca481f;
  --main: #334785;
  --error: #ff96af;
  --offline-status: #d3d3d3;
  --online-status: #22ea72;
  --bp-1280: 1280px;
  --bp-1440: 1440px;
}

@custom-media --xs (width >= 414px);
@custom-media --max-mobile (width >= 768px);
@custom-media --max-app-width (width >= 1200px);
@custom-media --portrait-phone (width >= 414px);
@custom-media --landscape-phone (width >= 576px);
@custom-media --tablet (width >= 768px);
@custom-media --laptop (width >= 992px);
@custom-media --desktop (width >= 1281px);
@custom-media --large-desktop (width >= 1600px);
