.btn {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  background: var(--Gradient-East-Bay, linear-gradient(111deg, #353a6a 22.16%, #2e4c97 106.61%));
  background-clip: text;
  -webkit-text-fill-color: transparent;

  &:hover {
    box-shadow: none;
  }
}

.chevron {
  margin-left: 5px;
  transition: transform 0.2s ease-in-out;
}

.open {
  transform: rotate(-180deg);
}
