.selectCountryValueContainer {
  display: flex;
  position: absolute;
  text-overflow: ellipsis;

  .selectCountryValueFlag {
    display: inline-block;
    height: 20px;
    width: 25px;
  }

  .selectCountryValueText {
    margin-left: 5px;
  }
}

/* Needed for cursor margin due to flag render on the left */
.selectCountryValueContainer + div > input {
  margin-left: 28px !important;
}
