.groupHeading {
  position: relative;
  left: 10px;
  background: var(--white);
  color: var(--main);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  padding: 0 7px;
  width: fit-content;
}

.groupWrapper {
  position: relative;
  margin: 0 12px;
}

.groupWrapper::before {
  content: '';
  width: 100%;
  border-bottom: 1px solid var(--main);
  opacity: 0.5;
  position: absolute;
  top: 50%;
}
