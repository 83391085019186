@import "./src/theme/_config.scss";

.approve {
  &-label {
    display: inline-block;
    min-width: 17px;
    min-height: 17px;
    border-radius: 3px;
    border: 1px solid $primary-color;
    margin: auto 10px;

    &.checked {
      background-color: $primary-color;
      position: relative;

      &:after {
        content: "";
        display: inline-block;
        width: 13px;
        height: 13px;
        position: absolute;
        left: 1px;
        top: 0;
        background-size: cover;
        background-image: url('./../../../assets/images/check.svg');
      }
    }
  }

  &-input {
    display: none;
  }
}
