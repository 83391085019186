.modal {
  width: 548px;

  .close {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  .wrapper {
    display: flex;
    width: 100%;
    max-width: 311px;
    flex-direction: column;
    text-align: left;
    position: relative;
    align-items: stretch;
  }

  .heading {
    width: 100%;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: var(--primary-color);
  }

  .actions {
    margin-top: 25px;

    .saveBtn {
      width: 100%;
      max-width: 311px;
      height: 50px;
      border-radius: 30px;
      color: var(--white);
      border: 1px solid rgba(23, 20, 46, 0.5);
      background-color: #334785;
      font-size: 18px;
    }
  }
}
