/*AG Grid styles*/
.ag-theme-material {
  --ag-header-column-resize-handle-display: block;
  --ag-header-column-resize-handle-height: 100%;
  --ag-header-column-resize-handle-width: 6px;
  --ag-header-column-resize-handle-color: transparent;

  --ag-card-radius: 10px;
  --ag-popup-shadow: var(--ag-card-shadow);
  --ag-tab-min-width: 350px;
}

.ag-theme-material,
.ag-theme-material .ag-header {
  font-family: var(--secondary-font), serif;
}

.ag-root {
  background-color: #fff;
  border-radius: 30px;
  padding: 0 20px;
}

.ag-root-wrapper {
  border-radius: 30px;
}

.grid-container {
  box-shadow: var(--non-focus-shadow);
  border-radius: 30px;
  max-height: max-content;
  height: 532px;

  &.schedules {
    border-top-right-radius: 0;
  }
  &:hover {
    box-shadow: 0px -1px 20px rgba(114, 152, 241, 0.45);
  }
}

.ag-header-cell {
  color: rgba(23, 20, 46, 0.5);
  font-weight: 500 !important;
  font-size: 14px;
  line-height: normal;
  padding: 0 5px;
  border-right: 1px solid rgba(110, 125, 180, 0.1) !important;
}

//.ag-header, .ag-pivot-off {
//  font-family: $secondary-font !important;
//  font-style: normal !important;
//  font-weight: 500 !important;
//  font-size: 14px !important;
//  color: rgba(23, 20, 46, 0.5) !important;
//}

.ag-column-hover {
  background: var(--white);
  box-shadow: 0 0 7px 2.5px rgba(114, 152, 241, 0.25);
  clip-path: inset(0px -15px 0px -15px);
  //width: 200px !important;
  z-index: 1;
  //transition: transform 0.3s ease;
  //transform: translateX(-20px);
}

.ag-cell {
  font-size: 14px;
  padding: 0 5px;
  border-right: 1px solid rgba(110, 125, 180, 0.1) !important;
}

.ag-cell:hover {
  background: #E4ECF8;
}

.ag-column-last {
  border-right: none !important;
}

.ag-theme-material .ag-cell-inline-editing {
  background: var(--white);
  padding: 0;
  height: unset;
  border-radius: unset;
  border: none !important;
  box-shadow: inset 0 0 0 1px #808080FF;
}

.ag-text-field-input {
  border: none !important;
  padding: 0 0 0 5px !important;
}

.ag-theme-material .ag-header-cell {
  background-color: var(--white) !important;
  transition: none !important;
}

.ag-ltr .ag-header-cell-resize {
  right: 1px;
  z-index: 1; // under the datepicker by Header
}

.ag-sort-indicator-container {
  margin-left: auto;
  margin-right: 12px;
}

.ag-floating-top {
  border: none !important;
}

.ag-dnd-ghost {
  min-width: 150px;
  cursor: grabbing;
}

.ag-overlay {
  z-index: 1;
}

.schedules .ag-root {
  padding-right: 0;
}

.schedules, .season-details {
  .ag-root {
    border-top-right-radius: 0 !important;
    padding-top: 0;
  }

  .ag-header-cell[col-id="actions"] {
    text-align: center;
  }

  .ag-header-cell[col-id="home_score"] {
    border-left: 1px solid rgba(110, 125, 180, 0.3);
  }
}

.ag-theme-material, .ag-header-cell, .ag-theme-material, .ag-header-group-cell, .ag-theme-material .ag-header {
  padding-right: 0 !important;
  border-bottom: none !important;
}

.ag-row {
  cursor: pointer;

  &:hover {
    background: #E4ECF8;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%; /* or 100px */
    opacity: 0.1;
    border-bottom: 1px solid #6E7DB4;
  }
}

.ag-header-row, .ag-row {
  border: none !important;
}
