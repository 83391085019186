.error {
  visibility: hidden;
  position: absolute;
  z-index: 10;
  right: 105%;
  pointer-events: none;
  min-height: 50px;
  font-size: 14px !important;
  background: #fff;
  border-radius: 15px;
  text-align: center;
  padding: 17px !important;
  width: fit-content;
  max-width: 250px;
  box-shadow: 0 0 3px 1px red inset;

  &::after {
    z-index: -1;
    top: 40px;
    left: calc(100% - 5px);
    content: '\00a0';
    height: 20px;
    width: 20px;
    background-color: #fff;
    display: block;
    position: absolute;
    box-sizing: border-box;
    border-color: transparent transparent #fff #fff;
    transform-origin: 0 0;
    transform: rotate(-135deg);
    box-shadow: -3px 3px 3px 0 rgba(255, 33, 33, 0.5);
  }
}

.reverse {
  left: 105%;
  text-orientation: revert;

  &::after {
    top: 10px;
    left: 5px;
    transform: rotate(45deg);
  }
}

.invalid {
  box-shadow: inset 0 0 4px 2px rgba(255, 33, 33, 0.5);
}

.showHideButton {
  background: transparent;
  width: fit-content;
  border: none;
  padding: 3px 2px;

  &:focus,
  &:focus-visible {
    outline: 1px solid #000;
  }
}

.show {
  filter: invert(77%) sepia(0%) saturate(0%) hue-rotate(139deg) brightness(90%) contrast(86%);
}

.hide {
  filter: invert(100%) sepia(1%) saturate(4673%) hue-rotate(168deg) brightness(129%) contrast(76%);
}

.subLabel {
  line-height: 21px;
  font-size: 13px;
  color: #202020;
  opacity: 0.5;
  margin-bottom: 7px;
}

.errorMessage {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #ff96af;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  bottom: -25px;
}

.icon {
  font-size: 20px;
  padding: 14px 10px 0 0;
}

.translucentOpacity {
  color: var(--translucent-opacity);
}
