.selectCountryOptionContainer {
  display: flex;
  padding: 8px 0;
  transition: background 60ms !important;
  cursor: pointer !important;

  &:hover {
    transition-delay: 60ms !important;
    background: #deebff !important;
    color: var(--primary-color);
  }

  .selectCountryOptionFlag {
    display: inline-block;
    margin-left: 16px;
    height: 20px;
    width: 25px;
  }

  .selectCountryOptionText {
    margin-left: 5px;
  }
}

.isSelected {
  background: #334785;
  color: #fff;
}
