.button {
  color: var(--black-20);
  border-radius: 40px;
  min-height: fit-content;
  min-width: fit-content;
  border: none;

  /* box-shadow: var(--non-focus-shadow); */
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &:hover {
    box-shadow: var(--main-box-shadow);
  }
}

.primary {
  height: 50px;
  min-width: 145px;
  color: var(--white);
  background: var(--gradient-east-bay);
  opacity: 0.9;
}

.secondary {
  height: 50px;
  min-width: 145px;
  color: #fff;
  background: var(--secondary-color) !important;
}

.outline {
  background-color: transparent;
  border: 1px solid rgba(23, 20, 46, 0.5);
  border-radius: 30px;
  color: rgba(23, 20, 46, 0.5);
  font-size: 18px;
  height: 50px;
  min-width: 145px;
}

.transparent {
  border-radius: 0;
  padding: 10px 35px;
  color: black;
  background: transparent;
  box-shadow: none;

  &:hover {
    box-shadow: none;
    text-decoration: underline;
  }
}

.link {
  color: var(--primary-color);
  background: transparent;
  box-shadow: none;

  &:hover {
    box-shadow: none;
    text-decoration: underline;
  }
}

.icon {
  padding: 0;
  background: none;
  margin: auto;
  min-width: 25px !important;
  min-height: 25px !important;
  box-shadow: none;

  &:hover {
    box-shadow: none;
    text-decoration: none;
  }
}
