.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 50px;
  max-width: 1200px;

  h2 {
    color: var(--secondary-color);
    font-family: Teko, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 96px;
    text-transform: uppercase;
  }

  h3 {
    color: var(--primary-color);
    font-family: Teko, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    text-transform: uppercase;
  }

  p {
    font-family: Hind, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 29px;
  }
}

.illustration {
  position: absolute;
  bottom: 0;
  right: 0;
  max-height: 80%;
  z-index: -1;
}
