.selectCountryWrapper {
  position: relative;

  .selectCountryLabel {
    padding: 0 20px;
    font-size: 12px;
    font-weight: 600;
    color: var(--mid-grey);
    line-height: 19.2px;
  }

  .selectCountryErrorMsg {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #ff96af;
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0;
    bottom: -25px;
  }
}

.disabled {
  &:hover {
    cursor: not-allowed !important;
  }
}
