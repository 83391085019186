.addIcon {
  margin-right: 10px;
}

.addTeamBtn {
  padding: 0 20px;
  height: 50px;
  color: var(--main);
  font-size: 16px;
  font-weight: 600;
  background: none;

  &:hover {
    cursor: pointer;
    color: inherit;
    background-color: #deebff;
  }
}
